import React from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

function Footer() {
  return (
    <div>
<Container fluid className="footer">
  &copy; 2024 - Design & Developed by <a href="https://nexoracle.com" target="_blank" rel="noopener noreferrer">NexOracle</a>
</Container>
    </div>
  );
}

export default Footer;
